export const environment = {
  production: false,
    // baseURL: "https://localhost:7253",
    // securityURl: "http://localhost:7144",

    // baseURL: 'https://wafiservicesapi.rega.gov.sa',
    // securityURl: 'https://wafisecurity.rega.gov.sa',
    // baseURL: "http://38.170.241.69:999",
    // securityURl: "http://38.170.241.69:7145"
    // baseURL: "http://38.170.241.69:333",
    // securityURl: "http://38.170.241.69:222",

    // baseURL: "https://10.27.10.101:888",
    // securityURl: "https://10.27.10.101:7144",

    // baseURL: 'https://wafiservicesapi.rega.gov.sa',
    // securityURl: 'https://wafisecurity.rega.gov.sa',
    // baseURL: "http://38.170.241.69:8888",
    // securityURl: "http://38.170.241.69:7777",
    // baseURL: "http://38.170.241.69:811",
    // securityURl: "http://38.170.241.69:833"

   // baseURL : "http://wafisystemapi.asgatech.com",
   // securityURl :"http://wafisecurity.asgatech.com"
   baseURL : "https://wafisystemapi.asgatech.com",
   securityURl :"https://wafisecurity.asgatech.com"


};
