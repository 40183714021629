import { Injectable } from '@angular/core';
import { customerResponse} from '../../../shared/models/customer.model';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from '../../../shared/models/base-response';
import { Configuration } from 'src/app/core/configurations/shared.config';

@Injectable()
export class UserDataService {
  customerData$: BehaviorSubject<customerResponse | null> = new BehaviorSubject<customerResponse | null>(null)
  constructor(private _httpClient: HttpClient) { }
  // API
  GetCustomer(): Observable<customerResponse | null> {
    return this._httpClient.get<BaseResponse<customerResponse>>(`${Configuration.APIs.Customer.GetById}`).pipe(
      map((res: BaseResponse<customerResponse>) => {
        if (res.data) {
          return res.data as customerResponse
        } else {
          return null
        }
      })
    )
  }
  // local Storage 
  setCustomerDataInLocalStorage(customerData: customerResponse): void {
    let customerDataStringified = JSON.stringify(customerData)
    localStorage.setItem('customerData', customerDataStringified)
    this.customerData$.next(customerData)
  }
  getCustomerDataFromLocalStorage(): customerResponse | null {
    let CustomerData = localStorage.getItem('customerData');
    return CustomerData && JSON.parse(CustomerData)
    // return this.customerData$.asObservable()
  }
  removeCustomerDatafromLocalStorage(): void {
    localStorage.removeItem('customerData')
  }
  // Observable method
  getCustomerData(): Observable<customerResponse | null> {
    return this.customerData$.asObservable()
  }
  updateCustomerData(customerData: customerResponse) {
    this.customerData$.next(customerData)
  }
}
